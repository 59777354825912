<template>
  <section>
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/records/operators"><a>Kartoteki</a></router-link>
          <router-link tag="li" to="/records/operators"><a>Operatorzy</a></router-link>
          <router-link tag="li" :to="{ name: 'OperatorsEdit', params: { id: this.$route.params.id }}" class="is-active"><a>Nazwa</a></router-link>
        </ul>   
      </nav>
    </portal-target>
    <OperatorsForm :id="this.$route.params.id" />
  </section>
</template>

<script>
  import OperatorsForm from '@/views/Records/Operators/OperatorsForm'
  export default {
    name: 'OperatorsEdit',
    components: {
      OperatorsForm
    }   
  }
</script>

<style lang="scss" scoped>

</style>