<template>
  <section>
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/records/operators"><a>Kartoteki</a></router-link>
          <router-link tag="li" to="/records/operators"><a>Operatorzy</a></router-link>
          <router-link tag="li" :to="{ name: 'OperatorsDetails', params: { id: this.$route.params.id }}" class="is-active"><a>{{ name }}</a></router-link>
       </ul>   
      </nav>
    </portal-target>
    <div class="ym-toptitle-box">
      <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
        <div  id="toptitle" class="level ym-toptitle">
          <div class="level-left">
            <router-link tag="a" to="/records/operators/" class="button xbtn is-medium is-transparent"><icon name="x"></icon></router-link>
            <h2 class="title level-left">{{ name }}</h2>
          </div>
          <div class='buttons level-right'>
            <button @click="userRemove" class="button is-medium is-light"><icon name="trash"></icon><span>Usuń</span></button>
            <router-link tag="a" :to="{ name: 'OperatorsEdit', params: { id: parseInt(this.$route.params.id) }}" class="button is-medium is-light"><icon name="edit"></icon><span>Edytuj</span></router-link>
            <a class="button is-medium is-dark"><icon name="check"></icon><span>Wybierz</span></a>
          </div>          
        </div>
      </fixed-header> 
    </div> 
    <div class="ym-whitebg pa40" v-if="operators">
        <div class="columns is-variable is-6">
            <div class="column is-6" v-if="operators.firmname">
                <p class="label-small">Pełna nazwa klienta</p>
                <p class="label is-uppercase">{{ operators.firmname }}</p>
            </div>
            <div class="column is-6" v-if="operators.nip">
                <p class="label-small">NIP</p>
                <p class="label is-uppercase">{{ operators.nip }}</p>
            </div>
        </div>      
        <div class="columns is-variable is-6">
            <div class="column is-6">
                <p class="label-small">Imię</p>
                <p class="label is-uppercase">{{ operators.firstname }}</p>
            </div>
            <div class="column is-6">
                <p class="label-small">Nazwisko</p>
                <p class="label is-uppercase">{{ operators.lastname }}</p>
            </div>
        </div>
        <div class="columns is-variable is-6">
            <div class="column is-6" v-if="operators.address">
                <p class="label-small">Ulica</p>
                <p class="label is-uppercase">{{ operators.address }}
                <br>{{ operators.postcode }} {{ operators.city }} 
                </p>
            </div>
            <div class="column is-6" v-if="operators.phone">
                <p class="label-small">Telefon</p>
                <p class="label is-uppercase">{{ operators.phone }}</p>
            </div>
        </div>
        <div class="columns is-variable is-6">
            <div class="column is-6">
                <p class="label-small">E-mail</p>
                <p class="label is-uppercase">{{ operators.email }}</p>
            </div>
          
        </div>
    </div>
    <div class="ym-whitebg pa40 mat25" v-if="operators && operators.roles_web">
      <div class="columns is-variable is-6 pab5">
          <div class="column is-12">
            <p class="label">Uprawnienia</p>
          </div>
      </div>
      <div class="columns is-variable is-6 is-multiline pat10">
        <div class="column is-6">
          <ul class="ym-checklist">
            <li :class="operators.roles_web.services.views ? '' : 'notavailable'">
              <icon :name="operators.roles_web.services.views ? 'check' : 'x'"></icon>
              <p>Zgłoszenia serwisowe</p>
            </li>
          </ul>
          <div class="patb5 pal30">
              <ul class="ym-checklist">
                  <li :class="operators.roles_web.services.remove ? '' : 'notavailable'">
                      <icon :name="operators.roles_web.services.remove ? 'check' : 'x'"></icon>
                      <p>Usuwanie</p>
                  </li>
                  <li style="display: none" :class="operators.roles_web.services.print ? '' : 'notavailable'">
                      <icon :name="operators.roles_web.services.print ? 'check' : 'x'"></icon>
                      <p>Drukowanie</p>
                  </li>
              </ul>
          </div>
        </div>
          <div class="column is-6">
                <ul class="ym-checklist">
                    <li :class="operators.roles_web.customers.views ? '' : 'notavailable'">
                        <icon :name="operators.roles_web.customers.views ? 'check' : 'x'"></icon>
                        <p>Klienci<br><span class="label-small">zarządzanie kartoteką</span></p>
                    </li>
                </ul>
                <div class="patb5 pal30">
                    <ul class="ym-checklist">
                        <li :class="operators.roles_web.customers.remove ? '' : 'notavailable'">
                            <icon :name="operators.roles_web.customers.remove ? 'check' : 'x'"></icon>
                            <p>Usuwanie</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="columns is-variable is-6 is-multiline">
            <div class="column is-6">
                <ul class="ym-checklist">
                    <li :class="operators.roles_web.technicans.views ? '' : 'notavailable'">
                        <icon :name="operators.roles_web.technicans.views ? 'check' : 'x'"></icon>
                        <p>Serwisanci<br><span class="label-small">zarządzanie kartoteką</span></p>
                    </li>
                </ul>
                <div class="patb5 pal30">
                    <ul class="ym-checklist">
                        <li :class="operators.roles_web.technicans.remove ? '' : 'notavailable'">
                            <icon :name="operators.roles_web.technicans.remove ? 'check' : 'x'"></icon>
                            <p>Usuwanie</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="column is-6">
                <ul class="ym-checklist">
                    <li :class="operators.roles_web.machineries.views ? '' : 'notavailable'">
                        <icon :name="operators.roles_web.machineries.views ? 'check' : 'x'"></icon>
                        <p>Maszyny<br><span class="label-small">zarządzanie kartoteką</span></p>
                    </li>
                </ul>
                <div class="patb5 pal30">
                    <ul class="ym-checklist">
                    <li :class="operators.roles_web.machineries.remove ? '' : 'notavailable'">
                        <icon :name="operators.roles_web.machineries.remove ? 'check' : 'x'"></icon>
                            <p>Usuwanie</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="columns is-variable is-6 is-multiline">
            <div class="column is-6">
                <ul class="ym-checklist">
                    <li :class="operators.roles_web.operators.views ? '' : 'notavailable'">
                        <icon :name="operators.roles_web.operators.views ? 'check' : 'x'"></icon>
                        <p>Operatorzy<br><span class="label-small">zarządzanie kartoteką</span></p>
                    </li>
                </ul>
                <div class="patb5 pal30">
                    <ul class="ym-checklist">
                    <li :class="operators.roles_web.operators.remove ? '' : 'notavailable'">
                        <icon :name="operators.roles_web.operators.remove ? 'check' : 'x'"></icon>
                            <p>Usuwanie</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
      </div>      
  </section>
</template>

<script>
  import FixedHeader from 'vue-fixed-header'
  import {mapActions} from 'vuex'
  import YMmodal from '@/components/Modal.vue';

  export default {
    name: 'OperatorsDetails',
    components: {
      FixedHeader
    },
    data: function () {
      return {
        operators: {
          firstname: '',
          lastname: ''
        }
      }
    }, 
    mounted() {
      this.getUser()
    },
    computed: {
      name: function () {
        return this.operators.firstname + ' ' + this.operators.lastname
      }
    },
    methods: {
      ...mapActions({
        getUserCall: 'records/getUser',
        removeUserCall: 'records/removeUser',
      }),
      getUser() {
        this.getUserCall({id: this.$route.params.id})
          .then(resp => this.operators = resp)
          .catch((error) => {
            this.operators = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
      },
      userRemove() {
        this.$buefy.modal.open({
          parent: this,
          component: YMmodal,
          hasModalCard: true,
          props: {
            title: 'Czy na pewno chcesz usunąć użytkownika?',
            content: `<span class='is-danger'>Usunięcia użytkownika nie można cofnąć.</span> Użytkownik pozostanie w utworzonych do tej pory zleceniach.`,
            cancel: 'Anuluj',
            ymHasIcon: true,
            ymIcon: 'trash',
            submit: `<span class="mal50i">Usuń</span>`,
            ymConfirm: true,
              ymOnConfirm: () => {
                this.removeUserCall({id: this.$route.params.id})
                .then(({ data }) => {
                  document.querySelector('.is-cancel').click()
                  this.$router.push({name: 'Operators'})
                  this.$buefy.toast.open({
                      duration: 5000,
                      message: data.alert[1],
                      type: 'is-success',
                      position: 'is-bottom'
                  })  
                })
                .catch(() => {
                  document.querySelector('.is-cancel').click()
                })                 
            }
          }
        }) 
      },      
    }             
  }
</script>

<style lang="scss" scoped>

</style>