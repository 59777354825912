<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit, errors }">
      <form class="login" @submit.prevent="handleSubmit(onSubmit)">
        <div class="ym-toptitle-box">
          <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
            <div id="toptitle" class="level ym-toptitle">
              <div class="level-left">
                <router-link tag="a" to="/records/service-technician" class="button xbtn is-medium is-transparent">
                  <icon name="x"></icon>
                </router-link>
                <h2 class="title" v-if="isNew">Nowy operator</h2>
                <h2 class="title" v-else>Edytuj operatora</h2>
              </div>
              <div class='buttons level-right'>
                <button class="button is-medium is-primary">
                  <icon name="check"></icon><span>Zapisz</span>
                </button>
              </div>
            </div>
          </fixed-header>
        </div>
        <div
          :class="errors['firstname'] && errors['firstname'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Imię</label>
              <span v-if="errors['firstname'] && errors['firstname'].length > 0"
                class="help is-danger">{{ errors['firstname'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="firstname">
                  <input name="firstname" v-model="user.firstname" class="input is-medium is-uppercase" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['lastname'] && errors['lastname'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Nazwisko</label>
              <span v-if="errors['lastname'] && errors['lastname'].length > 0"
                class="help is-danger">{{ errors['lastname'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="lastname">
                  <input name="lastname" v-model="user.lastname" class="input is-medium is-uppercase" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['firmname'] && errors['firmname'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Nazwa firmy</label>
              <span v-if="errors['firmname'] && errors['firmname'].length > 0"
                class="help is-danger">{{ errors['firmname'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="firmname">
                  <input name="firmname" v-model="user.firmname" class="input is-medium is-uppercase" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['email'] && errors['email'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Email</label>
              <span v-if="errors['email'] && errors['email'].length > 0"
                class="help is-danger">{{ errors['email'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="email">
                  <input name="email" v-model="user.email" class="input is-medium is-uppercase" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isNew"
          :class="errors['password'] && errors['password'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Hasło</label>
              <span v-if="errors['password'] && errors['password'].length > 0"
                class="help is-danger">{{ errors['password'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="password">
                  <b-input type="password" name="password" v-model="user.password" size="is-medium" password-reveal></b-input>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['phone'] && errors['phone'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Telefon</label>
              <span v-if="errors['phone'] && errors['phone'].length > 0"
                class="help is-danger">{{ errors['phone'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="phone">
                  <input name="phone" v-model="user.phone" class="input is-medium is-uppercase" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['nip'] && errors['nip'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_nip_short">NIP</label>
              <span v-if="errors['nip'] && errors['nip'].length > 0"
                class="help is-danger">{{ errors['nip'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="nip">
                  <input name="nip" v-model="user.nip" class="input is-medium is-uppercase" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['address'] && errors['address'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="">Ulica</label>
              <span v-if="errors['address'] && errors['address'].length > 0"
                class="help is-danger">{{ errors['address'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="" name="address">
                  <input name="address" v-model="user.address" class="input is-medium is-uppercase" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['city'] && errors['city'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="">Miasto</label>
              <span v-if="errors['city'] && errors['city'].length > 0"
                class="help is-danger">{{ errors['city'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="" name="city">
                  <input name="city" v-model="user.city" class="input is-medium is-uppercase" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['postcode'] && errors['postcode'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="">Kod pocztowy</label>
              <span v-if="errors['postcode'] && errors['postcode'].length > 0"
                class="help is-danger">{{ errors['postcode'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="" name="postcode">
                  <input name="postcode" v-model="user.postcode" class="input is-medium is-uppercase" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
    
      </form>
    </ValidationObserver>
    <div class="columns is-variable is-6 is-multiline" style="display: none">
        <div class="column is-4">
            <div class="ym-label">
                <label for="">Uprawnienia</label>
            </div>
        </div>
        <div class="column ym-column is-8">
            <div class="w100">
                <div class="columns is-variable is-1 is-multiline">
                    <div class="column ym-column totop is-12">
                        <div class="field">
                            <b-checkbox @click.native="checkServices($event)" v-model="roles_web.services.views" size="is-medium" native-value="1">Zgłoszenia serwisowe</b-checkbox>
                        </div>
                    </div>
                </div>
                <div class="columns is-variable is-1 is-multiline">
                    <div class="column ym-column totop is-6 pat15i">
                        <div class="w100">
                            <div class="field pal40 pab15">
                                <b-checkbox :disabled="roles_web.services.views ? false : true" v-model="roles_web.services.remove" size="is-medium" native-value="1">Usuwanie</b-checkbox>
                            </div>

                        </div>
                    </div>
                    <div class="column ym-column totop is-6">
                        <div class="field pal40 pab15">
                            <b-checkbox :disabled="roles_web.services.views ? false : true" v-model="roles_web.services.print" size="is-medium" native-value="1">Drukowanie</b-checkbox>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="columns is-variable is-1 is-multiline">
                    <div class="column ym-column totop is-12">
                        <div class="field">
                            <b-checkbox @click.native="checkCustomers($event)" v-model="roles_web.customers.views" size="is-medium" native-value="1">Klienci - zarządzanie kartoteką</b-checkbox>
                        </div>
                    </div>
                </div>
                <div class="columns is-variable is-1 is-multiline">
                    <div class="column ym-column totop is-6 pat15i">
                        <div class="w100">
                            <div class="field pal40 pab15">
                                <b-checkbox :disabled="roles_web.customers.views ? false : true" v-model="roles_web.customers.remove" size="is-medium" native-value="1">Usuwanie</b-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="columns is-variable is-1 is-multiline">
                    <div class="column ym-column totop is-12">
                        <div class="field">
                            <b-checkbox @click.native="checkTechnicans($event)" v-model="roles_web.technicans.views" size="is-medium" native-value="1">Serwisanci - zarządzanie kartoteką</b-checkbox>
                        </div>
                    </div>
                </div>
                <div class="columns is-variable is-1 is-multiline">
                    <div class="column ym-column totop is-6 pat15i">
                        <div class="w100">
                            <div class="field pal40 pab15">
                                <b-checkbox :disabled="roles_web.technicans.views ? false : true" v-model="roles_web.technicans.remove" size="is-medium" native-value="1">Usuwanie</b-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="columns is-variable is-1 is-multiline">
                    <div class="column ym-column totop is-12">
                        <div class="field">
                            <b-checkbox @click.native="checkMachineries($event)" v-model="roles_web.machineries.views" size="is-medium" native-value="1">Produkty - zarządzanie kartoteką</b-checkbox>
                        </div>
                    </div>
                </div>
                <div class="columns is-variable is-1 is-multiline">
                    <div class="column ym-column totop is-6 pat15i">
                        <div class="w100">
                            <div class="field pal40 pab15">
                                <b-checkbox :disabled="roles_web.machineries.views ? false : true" v-model="roles_web.machineries.remove" size="is-medium" native-value="1">Usuwanie</b-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="columns is-variable is-1 is-multiline">
                    <div class="column ym-column totop is-12">
                        <div class="field">
                            <b-checkbox @click.native="checkOperators($event)" v-model="roles_web.operators.views" size="is-medium" native-value="1">Operatorzy - zarządzanie kartoteką</b-checkbox>
                        </div>  
                    </div>
                </div>
                <div class="columns is-variable is-1 is-multiline">
                    <div class="column ym-column totop is-6 pat15i">
                        <div class="w100">
                            <div class="field pal40 pab15">
                                <b-checkbox :disabled="roles_web.operators.views ? false : true" v-model="roles_web.operators.remove" size="is-medium" native-value="1">Usuwanie</b-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>          
    </div>    
  </div>
</template>

<script>
  import FixedHeader from 'vue-fixed-header'
  import {mapActions} from 'vuex'
  import {
    ValidationObserver,
    ValidationProvider
  } from 'vee-validate';

  export default {
    name: 'OperatorsForm',
    props: {
      id: {
        type: Number,
        default: 0
      }
    },
    components: {
      FixedHeader,
      ValidationObserver,
      ValidationProvider
    },
    data: function () {
      return {
        user: {
          function_role: 'operator'
        },
        roles_web: {
          "services": {
            "views": false,
            "print": false,
            "remove": false
          },
          "customers": {
            "views": false,
            "remove": false
          },
          "technicans": {
            "views": false,
            "remove": false            
          },
          "machineries": {
            "views": false,
            "remove": false            
          },
          "operators": {
            "views": false,
            "remove": false            
          }
        },        
      }
    },
    computed: {
      isNew() {
        return this.id === 0 ? true : false
      }
    },
    methods: {
      ...mapActions({
        getUser: 'records/getUser',
        postUser: 'records/postUser',
        disabledSidebar: 'setDisabledSidebar'
      }), 
      onSubmit() {
        this.postUser({user: this.user, id: this.$route.params.id })
          .then(resp => {
            this.$router.push({name: 'OperatorsDetails', params: { id: resp.data.id }})
          })
          .catch((error) => {
            this.user = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          }) 

      },            
      checkServices: function(event) {
        if(!event.srcElement.checked) {
          this.roles_web.services.remove = false
          this.roles_web.services.print = false
        }
      },
      checkCustomers: function(event) {
        if(!event.srcElement.checked) {
          this.roles_web.customers.remove = false
        }
      },
      checkTechnicans: function(event) {
        if(!event.srcElement.checked) {
          this.roles_web.technicans.remove = false
        }
      },
      checkMachineries: function(event) {
        if(!event.srcElement.checked) {
          this.roles_web.machineries.remove = false
        }
      },        
      checkOperators: function(event) {
        if(!event.srcElement.checked) {
          this.roles_web.operators.remove = false
        }
      }
    },
    mounted() {
      this.disabledSidebar(true)
      if(!this.isNew) {
        this.getUser({id: this.$route.params.id})
          .then(resp => this.user = resp)
          .catch((error) => {
            this.user = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
      }
    },
    destroyed() {
      this.disabledSidebar(false)
    }
  }
</script>